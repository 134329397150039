import Typography from '@mui/material/Typography';
import markus_info from "../markus_personal_info.json";
import carsten_info from "../carsten_personal_info.json";
import { ComponentProps } from './ComponentProps';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

export default function Header(props: ComponentProps) {
    let personal_info = props.is_markus ?markus_info : carsten_info;
    return (
        <Box sx={{ flexGrow: 1, padding: 0, margin: "10px 0"}}>
          <AppBar position="static" color='transparent' sx={{boxShadow: "none"}}>
            <Toolbar style={{padding: 0}}>
                <Button href="/" color="inherit" style={{textTransform: 'none', padding: 0}}>
                    <Typography variant="h3" component="div" sx={{ flexGrow: 1, textAlign: "left"}}>
                        {personal_info.firstName} {personal_info.lastName}
                    </Typography>
              </Button>
              <Typography variant="h3" component="div" sx={{ flexGrow: 1, textAlign: "center"}}></Typography>

              {(props.is_markus) && <Button href="/books" color="inherit" sx={{display: {xs: 'none', md: 'block'}}}>Books I read (and liked)</Button>}
            </Toolbar>
          </AppBar>
        </Box>
        );
  }