import Typography from '@mui/material/Typography';
import markus_info from "../markus_personal_info.json";
import carsten_info from "../carsten_personal_info.json";
import { Box } from '@mui/material';
import { ComponentProps } from './ComponentProps';

export default function Introduction(props: ComponentProps) {
    let personal_info = props.is_markus ? markus_info : carsten_info;
    return (
        <Box style={{maxWidth: "650px",  textAlign: "left", fontSize: "1rem"}}>
            <Typography >{personal_info.introduction}</Typography>
            <br/>
            <Typography>For any inquiries, feel free to reach out to me via email!</Typography>
        </Box>
    );
  }

//   width: "calc(-208px + 100vw)", float: "left",