import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { TwitterTweetEmbed } from 'react-twitter-embed';


export default function ScholarInbox() {
    return (
        <>
        <Typography variant="h4" sx={{margin: "20px 0 20px 0"}}>Scholar Inbox</Typography>
        <Typography sx={{textAlign: "left"}}>
          I started working on <a href="https://www.scholar-inbox.com/">Scholar Inbox</a> in 2020, during the first semester of my masters. 
          Professor Geiger took me on as a research assistant, and the initial goal was to filter the email alerts from Google Scholar. 
          There was a lot of redundancy in these emails, and the daily process of keeping track of new papers was tedious, which was especially a problem in fast moving fields such as Computer Vision. 
          <br/>
          Later, we developed this into a website that filters the daily <a href="https://arxiv.org/">arxiv</a> publications and makes personal recommendations on new research papers.
          Using Scholar Inbox, researchers can forego sifting through the daily publications and simply see a ranked list of relevant papers.  
          <br/>
          I wrote my Master thesis on recommendation systems to further support the development of Scholar Inbox and then started my PhD with Professor Geiger.
          He wrote a very generous <a href="/recommendation_letter_2021.pdf">recommendation letter</a> at the end of my masters. 
          <br/>
          More students started working on the project and now we are a team of 7 (part time and full time).           
          Scholar Inbox supplied a tool at <a href="https://cvpr2023.thecvf.com/Conferences/2023">CVPR23</a> that ranked posters at the poster sessions according to the user's interests. 
          As CVPR has grown immensly, we provided a filtering tool for their poster sessions that contained over 400 posters each.
          <br/>
          Professor Geiger received a <a href="https://uni-tuebingen.de/en/university/news-and-publications/attempto-online/newsfullview-attempto-en/article/momentum-foerderung-der-volkswagen-stiftung-geht-gleich-drei-mal-an-die-universitaet-tuebingen/">grant</a> from the Volkswagen Foundation with over 700,000€ to further expand the project and I hope that we can make Scholar Inbox available to the public soon.
          <br/>
          I am thrilled to be part of this project and I am looking forward to see what the future holds for Scholar Inbox. 
          <br/>
          <br/>
          Finally, here is a tweet by Michael Black, director of the Max Planck Institute for Intelligent Systems:
        </Typography>
        <Box sx={{margin: "auto", maxWidth: "550px", marginTop: "50px"}}>
          <TwitterTweetEmbed  tweetId="1667758241038622720" />
        </Box>
        </>
    );
  }