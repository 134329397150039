
import * as React from 'react';
import SchoolIcon from '@mui/icons-material/School';
import { Box } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import DescriptionIcon from '@mui/icons-material/Description';
import EmailIcon from '@mui/icons-material/Email';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import markus_info from "../markus_personal_info.json";
import carsten_info from "../carsten_personal_info.json";
import { ComponentProps } from './ComponentProps';


export default function ProfilePicture(props: ComponentProps) {
    let personal_info = props.is_markus ? markus_info : carsten_info;
    console.log(personal_info)
    let width = 160;

    let buttonStyle = {
        color: "black",
        margin: "auto"
    }
    return (
        <Box width={width} style= {{float: "right"}}>
        <img src={personal_info.picture} style={{
            padding: ".25rem",
            border: "1px solid #dee2e6",
            borderRadius: ".25rem",
            maxWidth: "150px",
            height: "auto",
            
            }} alt="Profile picture"></img>
            <Box style={{width: "fit-content", margin: "auto"}}>
                {personal_info.github && <IconButton style={buttonStyle} href={personal_info.github}><GitHubIcon/></IconButton>}
                {personal_info.linkedin && <IconButton style={buttonStyle} href={personal_info.linkedin}><LinkedInIcon/></IconButton>}
                {personal_info.cv && <IconButton style={buttonStyle} href={personal_info.cv}><DescriptionIcon/></IconButton>}
                {personal_info.email && <IconButton style={buttonStyle} href={personal_info.email}><EmailIcon/></IconButton>}
            </Box>
            {/* <SchoolIcon/> */}
            
        </Box>
        );
}