import Typography from '@mui/material/Typography';
import markus_info from "../markus_personal_info.json";
import carsten_info from "../carsten_personal_info.json";
import { Box } from '@mui/material';
import { ComponentProps } from './ComponentProps';
import Button from '@mui/material/Button';

export default function Affiliations(props: ComponentProps) {
    let personal_info = props.is_markus ? markus_info : carsten_info;
    console.log(personal_info)
    let imgStyle = {
        height: (props.is_markus) ? "80px" : "50px",
        margin: (props.is_markus) ? "0 10px 0 10px" : "auto"
      }
    return (
        <Box sx={{justifyContent: "space-between", display: {xs: 'none', md: 'flex'}}}>
                {personal_info.affiliations.map((affiliation: any) => (
                    <Button href= {affiliation.url} title={affiliation.name} style={{borderRadius: "50px"}}><img src={affiliation.image} style={imgStyle} /></Button>
                ))}
        </Box>
    );
  }