import React from 'react';
import logo from './logo.svg';
import Typography from '@mui/material/Typography';
import ProfilePicture from './ProfilePicture';
import Introduction from './Introduction';
import Header from './Header';
import Affiliations from './Affilitations';
import ScholarInbox from './ScholarInbox';
import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Route } from 'react-router-dom';
import { ComponentProps } from './ComponentProps';
      

export default function ProfilePage(props: ComponentProps) {
    return(
        <Box id="introduction-box" style={{margin: "auto", maxWidth: 860}}>
          <Header is_markus={props.is_markus} />
          <Box sx={{minHeight: "260px", textAlign: "center"}}>
            <ProfilePicture is_markus={props.is_markus}/>
            <Introduction is_markus={props.is_markus}/>
          </Box>
          <Affiliations is_markus={props.is_markus} />
          {(props.is_markus) ? <ScholarInbox/> : null}
          {/* {(!props.is_markus) ? <Typography variant="h4" sx={{margin: "60px 0 20px 0"}}>Projects</Typography> : null} */}
        </Box>
    );
}