import './App.css';
import { useEffect, useState } from 'react';
import ProfilePage from './components/ProfilePage';
import BooksPage from './components/BooksPage';
import Typography from '@mui/material/Typography';
import {
  BrowserRouter as Router,
  Route, 
  Routes,
} from 'react-router-dom';
import Container from '@mui/material/Container';
import { ComponentType, ReactNode } from 'react';
import IndexPage from './components/IndexPage';
  
export default function App() {
  const isMarkus = false;
  const renderIndex = false;
  useEffect(() => {
    document.title = (isMarkus) ? "Markus Flicke" : "Carsten Flicke"
  });

  if (!renderIndex) {
    return (
      <Container maxWidth='xl'>
        <Router>
          <Routes>
            <Route path="/" element={<ProfilePage is_markus={isMarkus}/>} />
            {(isMarkus) && <Route path="/books" element={<BooksPage />} />}
          </Routes>
        </Router>
      </Container>
    );
  } else {
    return (
        <IndexPage />
    );
  }
}

